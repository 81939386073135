import {
  Container,
  Row,
  Col,
  Form,
  Image,
  ButtonGroup,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";
import Btn from "./../../components/common/Btn";
import { useEffect, useRef } from "react";
import { useState } from "react";

import { loadTossPayments } from "@tosspayments/payment-sdk";
import {
  useSearchParams,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import { AlertError, getApiUrl, PrintConsole } from "../../function/common";
import axios from "axios";
import Footer from "../../components/common/Footer";
// import QRFooter from './QRFooter';
import { setCommas, getTextLength } from "./../../function/common";
import Serviceterms from "./../../components/common/ServiceTerms";

import Header from "./components/Header";
import "./Payment.css";
import PaymentTerms from "./components/PaymentTerms";

export default function Payment() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  // const [Params] = useSearchParams();
  // console.log(localStorage.getItem('StoreURL'));
  // console.log(searchParams.get('productid'));

  const TmpTotalPrice = useRef(0);
  const TmpTotalEA = useRef(0);

  const [gift, setGift] = useState({
    recipientName: "",
    recipientPhoneNumber: "",
    orderPrice: "",
  });

  const payMode = useRef();

  const onChangeGift = (e) => {
    //데이터셋 변경시 id값 읽어 대입
    setGift({
      ...gift,
      [e.target.id]: e.target.value,
    });
    PrintConsole(gift);
  };

  const [isCheck, setIsCheck] = useState([]);
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);

    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [product, setProducts] = useState([]);

  function getProducts() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl(
            "v1/stores/" +
              localStorage.getItem("defaultStore") +
              "/products/" +
              searchParams.get("productid")
          ),
          // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setProducts(response.data);
          }
        })
        .catch(function (error) {
          // navigate('/404');
          console.log("error");
        });
    });
  }

  const [orderId, setOrderId] = useState();
  function getOrderId() {
    // console.log(localStorage.getItem('accessToken'));

    document.getElementById("submitBtn").setAttribute("disabled", "true");

    // console.log(localStorage.getItem('accessToken'));

    //handleShow

    let recipientPhoneNumber = "";
    let messageToRecipient = "";
    if (document.getElementById("recipientPhoneNumber")) {
      recipientPhoneNumber = document.getElementById(
        "recipientPhoneNumber"
      ).value;
    }
    if (document.getElementById("recipientName")) {
      messageToRecipient = document.getElementById("recipientName").value;
    }

    return new Promise(async function (resolve, reject) {
      let TmpAry = [];
      product.map((row) =>
        TmpAry.push({
          productId: row.row.productId,
          count: row.EA,
          price: row.row.price,
          totalPrice: row.EA * row.row.price,
        })
      );
      let obj = {
        orderLines: TmpAry,
        totalOrderPrice: TmpTotalPrice.current,
        totalOrderCount: TmpTotalEA.current,
        payType: payMode.current,
      };
      PrintConsole(obj);
      PrintConsole(obj.totalOrderPrice);
      if (obj.totalOrderPrice <= 1000) {
        handleShow();
        document.getElementById("submitBtn").removeAttribute("disabled");
      } else {
        await axios
          .post(
            getApiUrl(
              "v1/store/" + localStorage.getItem("defaultStore") + "/orders"
            ),
            obj,
            (axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("accessToken")),
            (axios.defaults.headers.common["ContentType"] = "application/json;")
          )
          .then(function (response) {
            // PrintConsole(response.data.orderId);
            document.getElementById("submitBtn").removeAttribute("disabled");
            tossloading(
              response.data.orderId,
              response.data.orderName,
              response.data.customerName
            );
          })
          .catch((error) => {
            AlertError(error);
            document.getElementById("submitBtn").removeAttribute("disabled");
          });
      }
    });
  }

  async function tossloading(orderId, orderName, customerName) {
    localStorage.setItem("storeDomain", localStorage.getItem("defaultStore"));
    localStorage.setItem("products", searchParams.get("productid"));
    localStorage.setItem("orderId", orderId);

    // console.log(process.env.REACT_APP_STORE_KEY);

    // const tossPayments = await loadTossPayments("test_ck_qLlDJaYngrok4Lye09nrezGdRpXx");
    const tossPayments = await loadTossPayments(
      process.env.REACT_APP_STORE_KEY
    );
    let tossMode = "";
    if (payMode.current === "CARD") {
      tossMode = "카드";
    } else if (payMode.current === "PHONE") {
      tossMode = "휴대폰";
    } else if (payMode.current === "EASY_PAY") {
      tossMode = "카카오페이";
    } else if (payMode.current === "ACCOUNT_TRANSFER") {
      tossMode = "계좌이체";
    } else {
      alert("결제 모듈 호출에 실패하였습니다.");
    }

    PrintConsole("orderId " + orderId);
    if (tossMode === "카카오페이") {
      //계좌이체, 휴대폰
      tossPayments.requestPayment("카드", {
        // 결제 수단 파라미터
        // 결제 정보 파라미터
        amount: TmpTotalPrice.current,
        taxFreeAmount: TmpTotalPrice.current,
        orderId: orderId,
        orderName: orderName,
        customerName: customerName,
        successUrl: process.env.REACT_APP_BASEURL + "success",
        // successUrl: "http://localhost:3000/" + "success",
        failUrl: process.env.REACT_APP_BASEURL + "failure",
        flowMode: "DIRECT",
        easyPay: "카카오페이",
      });
    } else {
      //계좌이체, 휴대폰
      tossPayments.requestPayment(tossMode, {
        // 결제 수단 파라미터
        // 결제 정보 파라미터
        amount: TmpTotalPrice.current,
        taxFreeAmount: TmpTotalPrice.current,
        orderId: orderId,
        orderName: orderName,
        customerName: customerName,
        successUrl: process.env.REACT_APP_BASEURL + "success",
        // successUrl: "http://localhost:3000/" + "success",
        failUrl: process.env.REACT_APP_BASEURL + "failure",
      });
    }

    document.getElementById("submitBtn").removeAttribute("disabled");
  }

  function setPayMode(mode) {
    payMode.current = mode;
  }

  useEffect(() => {
    //데이터 로딩

    setProducts(JSON.parse(localStorage.getItem("cart")));

    //데이터 로딩 완료
    setLoading(false);
  }, []);

  useEffect(() => {
    // console.log(product);
    product &&
      product.map(
        (row) => (
          //     TmpTotalPrice = TmpTotalPrice + (row.EA * row.row.price);
          (TmpTotalPrice.current =
            TmpTotalPrice.current + row.EA * row.row.price),
          (TmpTotalEA.current = TmpTotalEA.current + row.EA)
        )
      );

    // console.log(TmpTotalPrice);

    document.getElementById("Price").innerHTML = setCommas(
      TmpTotalPrice.current
    );
    document.getElementById("submitBtn").innerHTML =
      setCommas(TmpTotalPrice.current) + " 원  결제하기";
  }, [product]);

  if (loading) return <></>;

  return (
    <>
      <div className="demo">
        <div className="TPaymentPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">결제</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            {localStorage.getItem("accessToken") === null ? (
              <div className="d-none PaymentBox my-4-5">
                <div className="Recipient TicketBox pb-4-5 px-4-5">
                  <p className="PageTit2 mb-3">
                    식권 받는 분
                    <img
                      className="img-fluid"
                      src="https://www.flexdaycdn.net/public/images/static/flexdya-cms/emoji_hand2.png"
                      alt=""
                    />
                  </p>
                  <div className="Payment-Inputbox mb-2">
                    <input
                      type="text"
                      id="recipientName"
                      className="form-control InputType"
                      placeholder="받는 분의 이름을 입력해주세요."
                    />
                  </div>
                  <div className="Payment-Inputbox">
                    <input
                      type="number"
                      id="recipientPhoneNumber"
                      className="form-control InputType"
                      placeholder="받는 분의 연락처를 입력해주세요. ('-'없이 숫자만 입력)"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="PaymentBox my-4-5">
              <div className="Check TicketBox pb-4-5 px-4-5">
                <p className="PageTit2 mb-2">
                  식권확인
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/static/flexdya-cms/emoji_hand2.png"
                    alt=""
                  />
                </p>
                {product &&
                  product.map((row, idx) => (
                    <div
                      className="align-items-center justify-content-around g-0 row"
                      key={idx}
                    >
                      <div className="col-7">
                        <p className="prd-info">
                          {row.row.productName} / {row.EA} 개
                        </p>
                      </div>
                      <div className="col">
                        <p className="prd-price">
                          <span>{setCommas(row.EA * row.row.price)}</span>원
                        </p>
                      </div>
                    </div>
                  ))}

                {/* <div className="align-items-center justify-content-around g-0 row">
                                <div className="col-7">
                                    <p className="prd-info">
                                    스파클링 탄산음료 / 2개
                                </p>
                                </div>
                                <div className="col">
                                    <p className="prd-price">
                                        <span>1,800</span>원
                                    </p>
                                </div>
                            </div> */}
              </div>
            </div>

            <div className="PaymentBox my-4-5">
              <div className="Info TicketBox border-0 px-4-5">
                <p className="PageTit2 mb-4">
                  결제 정보
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/static/flexdya-cms/emoji_hand2.png"
                    alt=""
                  />
                </p>
                <div className="Total align-items-center d-flex justify-content-between mb-3 px-2">
                  <div className="tit">최종 결제 금액</div>
                  <div className="price">
                    <span id="Price"></span>원
                  </div>
                </div>
                <div className="form-radiowrap d-flex">
                  <div className="form-radiobox">
                    <input
                      className="CheckInput3 form-check-input"
                      id="CheckPay4"
                      type="radio"
                      name="CheckPay"
                      onClick={() => {
                        setPayMode("EASY_PAY");
                      }}
                    />
                    <label className="RadioLabel" htmlFor="CheckPay4">
                      <img
                        className="img-fluid"
                        alt=""
                        src="https://www.flexdaycdn.net/public/images/ticket/payment_icon1.png"
                      />
                      <span>카카오 페이</span>
                    </label>
                  </div>
                  <div className="form-radiobox">
                    <input
                      className="CheckInput3 form-check-input"
                      id="CheckPay1"
                      type="radio"
                      name="CheckPay"
                      onClick={() => {
                        setPayMode("CARD");
                      }}
                    />
                    <label className="RadioLabel" htmlFor="CheckPay1">
                      <img
                        className="img-fluid"
                        alt=""
                        src="https://www.flexdaycdn.net/public/images/ticket/payment_icon2.png"
                      />
                      <span>카드 결제</span>
                    </label>
                  </div>
                  {/* <div className="form-radiobox">
                                    <input className="CheckInput3 form-check-input"
                                        id="CheckPay2" type="radio" name="CheckPay" onClick={ () => { setPayMode('PHONE'); }} />
                                    <label className="RadioLabel" htmlFor="CheckPay2">
                                    <img className="img-fluid" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/payment_icon2.png" />
                                        <span>휴대폰 결제</span>
                                    </label>
                                </div> */}
                  {localStorage.getItem("defaultStore") != "dasom" ? (
                    <div className="form-radiobox">
                      <input
                        className="CheckInput3 form-check-input"
                        id="CheckPay3"
                        type="radio"
                        name="CheckPay"
                        onClick={() => {
                          setPayMode("ACCOUNT_TRANSFER");
                        }}
                      />
                      <label className="RadioLabel" htmlFor="CheckPay3">
                        <img
                          className="img-fluid"
                          alt=""
                          src="https://www.flexdaycdn.net/public/images/ticket/payment_icon3.png"
                        />
                        <span>계좌 이체</span>
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="form-radiobox mb-2 position-relative">
                                <input className="CheckInput3 end-0 form-check-input position-absolute top-50 translate-middle"
                                    id="CheckPay1" type="radio" value="" name="CheckPay" onClick={ () => { setPayMode('CARD'); }} />
                                <label className="RadioLabel align-items-center d-flex" htmlFor="CheckPay1">
                                    <span>카드 결제</span>
                                </label>
                            </div> */}
                {/* <div className="form-radiobox mb-2 position-relative">
                                <input className="CheckInput3 end-0 form-check-input position-absolute top-50 translate-middle"
                                    id="CheckPay2" type="radio" value="" name="CheckPay" onClick={ () => { setPayMode('PHONE'); }} />
                                <label className="RadioLabel align-items-center d-flex" htmlFor="CheckPay2">
                                    <span>휴대폰 결제</span>
                                </label>
                            </div> */}
                {/* <div className="form-radiobox mb-2 position-relative">
                                <input className="CheckInput3 end-0 form-check-input position-absolute top-50 translate-middle"
                                    id="CheckPay3" type="radio" value="" name="CheckPay" onClick={ () => { setPayMode('ACCOUNT_TRANSFER'); }}/>
                                <label className="RadioLabel align-items-center d-flex" htmlFor="CheckPay3">
                                    <span>계좌 이체</span>
                                </label>
                            </div> */}

                <div className="Payment-terms">
                  주문 내용 및 결제 조건을 확인했으며 결제에 동의합니다.
                  <a href="#none" className="termsBtn">
                    <PaymentTerms />
                  </a>
                </div>
              </div>
            </div>

            <div className="Payment-Confirm px-4-5 mb-4">
              <button
                type="button"
                id="submitBtn"
                className="btn btn-default"
                onClick={() => {
                  getOrderId();
                }}
              >
                {product && setCommas(product.price)} 원 결제하기
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        size="sm"
        centered
        className="demoModal modal-prdAdd text-center"
      >
        <Modal.Header>
          {/* <Modal.Title>
                        일일 구매 한도 초과
                    </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>1,000원부터 결제 가능합니다.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="modal-primary"
            className="btn btn-clear w-100"
            onClick={handleClose}
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
